import styled from 'styled-components';

export const Container = styled.main`
  margin-top: 40px;

  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
`;
